import { unwrapResult } from '@reduxjs/toolkit';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  FilterGeo,
  PageController,
  SearchResultsInitialState,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import clsx from 'clsx';
import { StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import { FulfillmentType, GTM_EVENT } from 'src/helpers/Constants';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import ComponentContext from 'lib/context/ComponentContext';
import { PSP } from 'models/PetSuppliesPlus.Model';
// import { useRouter } from 'next/router';
import { BuyerAddress, Order } from 'ordercloud-javascript-sdk';
import { useContext, useEffect } from 'react';
import useDictionary from 'src/hooks/useDictionary';
import {
  clearCurrentOrderWithoutInitialized,
  patchOrder,
  retrieveOrder,
  saveShippingAddress,
} from 'src/redux/ocCurrentOrder';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { findStoreTailwindVariant } from 'tailwindVariants/components/findStoreTailwindVariant';
import { convertMilesToKM } from 'src/utils/milestokm';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';

type inminicart = true | false | undefined;

export type GenericStoreLocatorProps =
  (PSP.Sitecore.templates.PetSuppliesPlus.FulfillmentSwitcher.Fields.FulfillmentSwitcher &
    PSP.Sitecore.templates.PetSuppliesPlus.Store.Fields.StoreLocator) & {
    handleModalClose: () => void;
    coordinates: { lat: number; lng: number };
    showMainLoader: (value: boolean) => void;
    setFindStorePopupOpen: (value: boolean) => void;
    setShowHardError: (value: boolean) => void;
    setShowNoDeliveryError: (value: boolean) => void;
    setRenderStoreWidget: (value: boolean) => void;
    setLoading?: (value: boolean) => void;
    formShippingAddress?: Partial<BuyerAddress>;
    inMiniCart: boolean;
  };

const GenericStoreLocator = ({
  fields,
  handleModalClose,
  coordinates,
  showMainLoader,
  setFindStorePopupOpen,
  setShowHardError,
  setShowNoDeliveryError,
  setRenderStoreWidget,
  formShippingAddress,
  setLoading,
  inMiniCart,
}: GenericStoreLocatorProps) => {
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const setRadius = fields?.globalRadiusInMiles?.value
    ? fields?.globalRadiusInMiles?.value
    : myStoreData?.radiusInMiles;
  // added km statically as circleRadius used other places as well
  const milestokm = convertMilesToKM(setRadius).toFixed(0);
  const dispatch = useOcDispatch();

  // const router = useRouter();
  const {
    storeListing,
    storeItem,
    storeDetailsWrapper,
    storeName,
    myStoreWrapper,
    myStoreTxt,
    selectStoreCTA,
    selectStoreWrapper,
    storeDetail,
    storeThumbnailImage,
  } = findStoreTailwindVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
    inminicart: inMiniCart as inminicart,
  });

  type InitialState = SearchResultsInitialState<'itemsPerPage' | 'sortType'>;
  const {
    queryResult: { isFetching, isLoading, data: { content: storesList = [] } = {} },
    query,
  } = useSearchResults<StoreProps, InitialState>({
    state: {
      itemsPerPage: Number(fields?.numberOfRecords?.value),
      sortType: 'near_by_distance_asc',
    },
  });

  const { getDictionaryValue } = useDictionary();

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const geoFilter = new FilterGeo('location', `${milestokm}km`);

  useEffect(() => {
    query.getRequest().resetSearchFilter().setSearchFilter(geoFilter);
  }, []);

  useEffect(() => {
    PageController.getContext().setGeo({
      location: { lat: coordinates?.lat, lon: coordinates?.lng },
    });
  }, [coordinates]);

  useEffect(() => {
    if (!(isLoading || isFetching)) {
      if (storesList?.length <= 0) {
        setShowHardError(false);
        setShowNoDeliveryError(true);
        setRenderStoreWidget(false);
        setLoading && setLoading(false);
        return;
      }
      setFindStorePopupOpen(true);
      setLoading && setLoading(false);
    }
  }, [isLoading, isFetching]);

  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);

  async function selectStoreClick(store: { storeid: string }) {
    if (store?.storeid === myStoreData?.storeId) {
      sendStoreGTMData({
        eventName: GTM_EVENT?.selectStore,
        storeId: store?.storeid,
        storesShown: storesList?.length?.toString(),
      });

      handleModalClose();
      const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
      showMainLoader(true);
      //Save shipping Address

      if (formShippingAddress) {
        if (currentOrder?.lineItems && currentOrder?.lineItems?.length > 0) {
          await dispatch(saveShippingAddress(formShippingAddress));
        } else {
          localStorage.setItem('selected_delivery_address', JSON.stringify(formShippingAddress));
        }
        if (currentOrder?.order && currentOrder?.order?.ID) {
          await dispatch(patchOrder({ request }));
          showMainLoader(false);
        } else {
          localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
          showMainLoader(false);
        }
      }
    } else {
      //Show Warning Popup while switch to another store.
      setcomponentContextData({
        ...componentContextData,
        storeId: store?.storeid,
        fromFulfillment: true,
        callBackFunctionAfterStoreChange: async () => {
          handleModalClose();
          showMainLoader(true);
          // const redirectUrl = '/'; // TODO: will this url configure from CMS?
          const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
          const retrieveStoreOrder = await dispatch(retrieveOrder());
          const responsesStoreOrder = unwrapResult(retrieveStoreOrder);
          if (responsesStoreOrder === undefined) {
            await dispatch(clearCurrentOrderWithoutInitialized());
            if (formShippingAddress) {
              localStorage.setItem(
                'selected_delivery_address',
                JSON.stringify(formShippingAddress)
              );
              localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
            }
          } else {
            await dispatch(retrieveOrder());
            if (responsesStoreOrder?.LineItems && responsesStoreOrder?.LineItems?.length > 0) {
              if (formShippingAddress) {
                await dispatch(await saveShippingAddress(formShippingAddress));
              }
              localStorage.removeItem('selected_fulfillment_Method');
            }
          }
          // if (currentOrder?.lineItems && currentOrder?.lineItems?.length > 0) {
          //   if (formShippingAddress) {
          //     await dispatch(await saveShippingAddress(formShippingAddress));
          //   }
          // } else if (formShippingAddress) {
          //   localStorage.setItem('selected_delivery_address', JSON.stringify(formShippingAddress));
          // }
          if (responsesStoreOrder?.Order && responsesStoreOrder?.Order?.ID) {
            await dispatch(patchOrder({ request }));
            showMainLoader(false);
          } else {
            // localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
            showMainLoader(false);
          }

          // router?.push(redirectUrl);
        },
      });
    }
  }
  return (
    <>
      {!(isLoading || isFetching) && storesList?.length > 0 && (
        <div className={storeListing()}>
          {storesList
            ?.filter((store) => store?.xp_dfs)
            .map((store) => {
              return (
                <div
                  className={storeItem()}
                  key={store?.id}
                  data-storeid={store?.storeid}
                  data-companyname={store?.companyname}
                >
                  <div className={clsx('storeimage', 'w-3/12')}>
                    {store?.xp_storeimage && (
                      <NextImage
                        className={storeThumbnailImage()}
                        field={{
                          value: {
                            src: `${store?.xp_storeimage[0]}`,
                            alt:
                              myStoreData?.storeId === store?.storeid
                                ? myStoreData?.storeName
                                : store?.state,
                            width: '124',
                            height: '124',
                          },
                        }}
                      />
                    )}
                  </div>
                  <div className="flex flex-col flex-[1_0_0] gap-[12px]">
                    <div className={storeDetailsWrapper()}>
                      {myStoreData?.storeId === store?.storeid && (
                        <div className={myStoreWrapper()}>
                          <div className="storeImage">
                            <NextImage
                              field={{
                                value: {
                                  src: fields?.storeImageIcon?.value?.src,
                                  height: '24',
                                  width: '24',
                                  alt: myStoreData?.storeName,
                                },
                              }}
                            />
                          </div>
                          <span className={myStoreTxt()}>{getDictionaryValue('MyStore')}</span>
                        </div>
                      )}

                      <div className={storeDetail()}>
                        <p className={storeName()}>{store?.firstname}</p>
                      </div>
                    </div>

                    <div className={selectStoreWrapper()}>
                      <LinkHelper
                        className={selectStoreCTA()}
                        onClick={async (e) => {
                          e.preventDefault();
                          await selectStoreClick(store);
                        }}
                        field={{
                          value: {
                            href: '/',
                            text: getDictionaryValue('SelectStore'),
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};
const FindStorePopupWidget = widget(GenericStoreLocator, WidgetDataType.SEARCH_RESULTS, 'store');

export default FindStorePopupWidget;
