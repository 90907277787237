/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
// import { ComponentProps } from '@sitecore-feaas/clientside';
import { patchOrder } from 'src/redux/ocCurrentOrder';
import { NextImage, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Order } from 'ordercloud-javascript-sdk';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  hardErrorIcon,
  softErrorIcon,
} from 'src/helpers/Constants';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { fulfillmentSwitchTailwindVariant } from 'tailwindVariants/components/fulfillmentSwitchTailwindVariant';
import { useContext, useEffect, useState } from 'react';
import {
  Formik,
  FormikHelpers,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FormikErrors,
  FormikValues,
  FormikTouched,
} from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import AutoCompleteWrapper from 'src/helpers/Form/AutoCompleteWrapper';
import useDictionary from 'src/hooks/useDictionary';
import FindStorePopupWidget from './FindStorePopupWidget';
import { PSP } from 'models/PetSuppliesPlus.Model';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import useOcCart from 'src/hooks/useOcCart';
import { onAutoSuggestionSubmitClick } from 'src/utils/handlePlaceChanged';
import { useFullfillmentSwitcherReplacement } from 'src/helpers/utility/AutoshipHelper';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import Loader from 'components/Loader/Loader';
import ComponentContext from 'lib/context/ComponentContext';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import GetStoresWidget from 'components/ProductDetail/ChangeStorePopup/GetStoresWidget';

type fulfilmentSwitchProps =
  PSP.Sitecore.templates.PetSuppliesPlus.FulfillmentSwitcher.Fields.FulfillmentSwitcher & {
    params: any;
    componentName?: string;
  };
interface FormValuesTypes {
  [key: string]: string;
}

type IsSmallPopup = true | false | undefined;

export const FulfillmentSwitch = ({
  fields,
  params,
  componentName,
}: fulfilmentSwitchProps): JSX.Element => {
  const {
    container,
    miniCartContainer,
    currentFulfillmentTxt,
    switchToLink,
    deliveryPopupSpacing,
    deliveryTopTextSection,
    dialogueTitle,
    dialogueShortDescription,
    FieldsRequiredMessage,
    formFieldRow,
    formField,
    inputField,
    errorStyle,
    fieldLabel,
    formContainer,
    dropDownField,
    submitBtn,
    formWrapper,
    formSubmitRow,
    loaderWrapper,
    changeStoreWrapper,
    findStoreContainer,
    hardError,
    mainLoaderWrapper,
    softError,
    promotionLabel,
    fulfillmentMessage,
  } = fulfillmentSwitchTailwindVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
    isSmallPopup: params?.IsInMinicart === '1' ? true : (false as IsSmallPopup),
    isPromotion: fields?.promotionMessage?.value !== '' ? true : false,
  });

  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const { getProductLineItems } = useOcCart();
  const productlineitems = getProductLineItems();
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);

  //const { lineItems } = useOcCurrentOrder();
  const ocShippingAddress = productlineitems?.[0]?.ShippingAddress;
  const [delivery, setDelivery] = useState<boolean>();

  const dispatch = useOcDispatch();
  const isSwitchEnable = myStoreData.disableDelivery !== 1; // Show switcher if delivery is enabled to the store.
  // const [, setDeliveryPopupOpen] = useState(false); // Open popup state.
  const [findStorePopupOpen, setFindStorePopupOpen] = useState(false); // Find store open state.
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showHardError, setShowHardError] = useState<boolean>(false);
  const [showSoftError, setShowSoftError] = useState<boolean>(false);
  const [showNoDeliveryError, setShowNoDeliveryError] = useState<boolean>(false);
  const [renderStoreWidget, setRenderStoreWidget] = useState<boolean>(false);
  const [formShippingAddress, setFormShippingAddress] = useState({
    Street1: '',
    Street2: '',
    City: '',
    State: '',
    Zip: '',
    Country: '',
  });
  // setShowHardError(false);
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  });

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || cart?.xp?.Fulfillment;
    setDelivery(newMethod === FulfillmentType.DFS);
  }, [cart?.xp?.Fulfillment, componentContextData?.isDFSMethodChanged]);

  const { countriesRegions } = useGraphQlDataContext();

  useEffect(() => {
    setcomponentContextData({
      ...componentContextData,
      showDeliveryAvailabilityPopup: false,
      openDeliveryAvailabilityPopupFromPDP: false,
    });
  }, []);
  const { getDictionaryValue } = useDictionary();

  const promoMessage = useFullfillmentSwitcherReplacement(fields?.promotionMessage?.value ?? '');

  // const getMinicartSelector = document.getElementsByClassName('fullfillment-popup');
  // const ele = document?.getElementsByClassName('FulfillmentSwitcher');

  //sending GTM Data when No store found
  useEffect(() => {
    showHardError &&
      sendStoreGTMData({
        eventName: GTM_EVENT?.deliveryAvailError,
        storeId: myStoreData?.storeId,
        error: GTMLabels?.deliveryStoreError,
      });
  }, [showHardError]);

  //sending GTM Data when No store found
  useEffect(() => {
    showSoftError &&
      sendStoreGTMData({
        eventName: GTM_EVENT?.deliveryAvailError,
        storeId: myStoreData?.storeId,
        error: GTMLabels?.deliveryAvailabilityError,
      });
  }, [showSoftError]);

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem('selected_delivery_address')) {
      const localStorageFormValues = JSON.parse(
        localStorage.getItem('selected_delivery_address') || ''
      );
      setFormShippingAddress(localStorageFormValues);
    }
  }, []);

  //handling scroll to top when error occur in delivery address(MiniCart FulfillmentSwitcher )
  useEffect(() => {
    const el1 = document.getElementsByClassName('formSection');
    if (el1 && showNoDeliveryError) {
      el1?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [showNoDeliveryError]);

  // if (params?.IsInMinicart !== '1') {
  if (!myStoreData || Object.keys(myStoreData).length === 0 || !isSwitchEnable) {
    return <div className="fulfillment-switcher" hidden></div>;
  }

  const handleSwitchfulfillment = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    const getMinicartSelector = document.getElementsByClassName('fullfillment-popup');
    const ele = document?.getElementsByClassName('FulfillmentSwitcher');
    //If FulfillmentType is DFS then switch directly.
    if (delivery) {
      setMainLoader(true);
      if (currentOrder?.order && currentOrder?.order?.ID) {
        const request: Order = { xp: { Fulfillment: FulfillmentType.BOPIS } };
        await dispatch(patchOrder({ request }));
        setMainLoader(false);
      } else {
        localStorage.setItem('selected_fulfillment_Method', FulfillmentType.BOPIS);
        setcomponentContextData({
          ...componentContextData,
          isDFSMethodChanged: !componentContextData?.isDFSMethodChanged,
        });
        setMainLoader(false);
      }
    } else {
      //This will treat as a BOPIS.
      // setDeliveryPopupOpen(true);
      if ((e.target as HTMLElement).getAttribute('data-attr') == 'from-minicart') {
        Array.from(getMinicartSelector).forEach((element: Element) => {
          element.classList.add('hidden');
        });
        ele && ele[0]?.setAttribute('style', 'display:none');
        // ele[0]?.style?.display = 'hidden';
      }
      if (componentContextData?.openMiniCart) {
        const ele = document?.getElementsByClassName('FulfillmentSwitcher');
        Array.from(ele).forEach((element: Element) => {
          element.classList.add('hidden');
        });
      }

      setcomponentContextData({
        ...componentContextData,
        showDeliveryAvailabilityPopup: true,
      });
      setRenderStoreWidget(false);
      setFindStorePopupOpen(false);
      setLoading(false);
      return;
    }
  };

  //Submit action of Delivery Availability form.
  const handleDeliveryPopupSubmitAction = async (formValues: FormValuesTypes): Promise<void> => {
    setLoading(true);
    setShowNoDeliveryError(false);
    setShowHardError(false);
    setShowSoftError(false);
    setRenderStoreWidget(false);
    const googleAddressValidation = await onAutoSuggestionSubmitClick(
      Object.values(formValues).join(','),
      { bypassBoundErrorCheck: true }
    );

    setFormShippingAddress({
      Street1: formValues.AddressLine1,
      Street2: formValues.addressLine2,
      City: formValues.city,
      State: formValues.stateCode,
      Zip: formValues.ZipCode,
      Country: formValues.country,
    });

    const addressValidation = await googleAddressValidation;
    validateGoogleAddressValidation(addressValidation);

    if (googleAddressValidation.isError || showHardError) {
      setLoading(false);
      setShowHardError(true);
    } else {
      addressValidation?.results?.geometry?.location_type !== ('APPROXIMATE' || 'UNKNOWN') &&
        setCoordinates({ lat: googleAddressValidation.lat, lng: googleAddressValidation.lng });
    }
  };
  // Show Error based on levels take this scenarios from live site code implementation.
  const validateGoogleAddressValidation = (address: any) => {
    const locationType = address?.results?.geometry?.location_type;

    switch (locationType) {
      case 'ROOFTOP':
        showSelectedWarningLevel(0);
        break;
      case 'APPROXIMATE':
        showSelectedWarningLevel(2);
        break;
      case 'GEOMETRIC_CENTER':
        showSelectedWarningLevel(1);
        break;
      case 'RANGE_INTERPOLATED':
        showSelectedWarningLevel(1);
        break;
      case 'UNKNOWN':
        showSelectedWarningLevel(2);
        break;
    }
  };
  // Show Error based on levels take this scenarios from live site code implementation.
  const showSelectedWarningLevel = (level: number) => {
    switch (level) {
      case 2:
        setShowHardError(true);
        setRenderStoreWidget(true);
        break;
      case 1:
        setShowSoftError(true);
        setRenderStoreWidget(true);
        break;
      default:
        setRenderStoreWidget(true);
        break;
    }
  };

  // handle modal Close functionality:
  const handleModalClose = () => {
    const getMinicartSelector = document.getElementsByClassName('fullfillment-popup');
    const ele = document?.getElementsByClassName('FulfillmentSwitcher');
    // setDeliveryPopupOpen(false);
    setcomponentContextData({
      ...componentContextData,
      showDeliveryAvailabilityPopup: false,
      openDeliveryAvailabilityPopupFromPDP: false,
      isDFSMethodChanged: !componentContextData?.isDFSMethodChanged,
    });
    setRenderStoreWidget(false);
    Array.from(getMinicartSelector).forEach((element: Element) => {
      element.classList.remove('hidden');
    });
    setLoading(false);
    ele && ele[0]?.removeAttribute('style');
    setFindStorePopupOpen(false);
  };

  // Delivery Availability popup form initial values:
  const initialValues = {
    AddressLine1: formShippingAddress?.Street1 || ocShippingAddress?.Street1 || '',
    addressLine2: formShippingAddress?.Street2 || ocShippingAddress?.Street2 || '',
    city: formShippingAddress?.City || ocShippingAddress?.City || '',
    stateCode: formShippingAddress?.State || ocShippingAddress?.State || '',
    ZipCode: formShippingAddress?.Zip || ocShippingAddress?.Zip || '',
    // state: ocShippingAddress?.State || '',
    country: formShippingAddress?.Country || ocShippingAddress?.Country || 'US',
  };

  // Field mapping for Google autocomplete functionality.
  const autoCompleteFieldMap = {
    addressLine1: 'AddressLine1',
    addressLine2: 'addressLine2',
    city: 'city',
    stateCode: 'stateCode',
    zipcode: 'ZipCode',
    // state: 'state',
    country: 'country',
  };

  // Delivery availability popup form validation schema. example usage: https://formik.org/docs/guides/validation#validationschema
  const FindStorePopupSchema = Yup.object().shape({
    AddressLine1: Yup.string()
      .matches(/^[a-zA-z]*[0-9]+/, fields?.streetNumberRequireMessage?.value)
      .required(fields?.addressLine1RequiredMessage?.value),
    city: Yup.string().required(fields?.cityRequiredMessage?.value),
    stateCode: Yup.string().required(fields?.stateRequiredMessage?.value),
    ZipCode: Yup.string()
      .min(5, fields?.zipMinLengthValidationMessage?.value)
      .max(10, fields?.zipMaxLengthValidationMessage?.value)
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, fields?.zipInvalidValueMessage?.value)
      .required(fields?.zipRequiredMessage?.value),
  });

  // Getting state options data from redux (lookupSlice)
  const getStatesOptions = (countryCode: string): JSX.Element[] => {
    const country = countriesRegions?.find((country) => country?.code?.value === countryCode);
    if (!country) return [];
    return country.regions.targetItems.map((region) => (
      <option key={region?.code?.value} value={region?.code?.value}>
        {region?.name?.value}
      </option>
    ));
  };
  // Function for setting the border color of delivery availability form.
  const getBorderStyle = (
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>,
    field: string
  ) => `border-${errors?.[field] && touched[field] ? 'system-red' : 'color-border-mid'}`;

  // Handle the change address link switch back to show delivery availibility form popup.
  function handleChangeAddressLink(): void {
    setRenderStoreWidget(false);
    setFindStorePopupOpen(false);
    // setDeliveryPopupOpen(true);
    setcomponentContextData({ ...componentContextData, showDeliveryAvailabilityPopup: true });
  }

  const isMiniCart = params?.IsInMinicart === '1';

  return (
    <>
      <div
        className={`${params?.IsInMinicart !== '1' ? container() : miniCartContainer()} ${
          params?.Styles
        } `}
      >
        {params?.IsInMinicart !== '1' && fields?.promotionMessage?.value !== '' && (
          <p className={promotionLabel()}>{promoMessage}</p>
        )}
        <div className={params?.IsInMinicart !== '1' ? fulfillmentMessage() : ''}>
          {params?.IsInMinicart !== '1' && (
            <Text
              field={delivery ? fields?.deliveryTitle : fields?.pickupTitle}
              tag="p"
              className={currentFulfillmentTxt()}
            />
          )}
          {fields?.switchToPickupCTAText?.value && fields?.switchToDeliveryCTAText?.value && (
            <LinkHelper
              attr={componentContextData?.openMiniCart ? 'from-minicart' : ''}
              className={switchToLink()}
              onClick={(e) => {
                e.preventDefault();
                handleSwitchfulfillment(e);
              }}
              field={{
                value: {
                  href: '/',
                  text: delivery
                    ? fields?.switchToPickupCTAText?.value
                    : fields?.switchToDeliveryCTAText?.value,
                },
              }}
            />
          )}
          {mainLoader && (
            <>
              <div className={mainLoaderWrapper()}>
                <Loader />
                {delivery
                  ? getDictionaryValue('SwitchingToPickup')
                  : getDictionaryValue('SwitchingToDelivery')}
              </div>
            </>
          )}
        </div>
      </div>
      <ModalWrapper
        isMiniCart={isMiniCart}
        showCloseButtonInModalHeader={false}
        closeModalOnOverlayClick={true}
        onCloseClick={handleModalClose}
        showModal={componentContextData?.showDeliveryAvailabilityPopup}
        customPopup={true}
        popupSpacing={deliveryPopupSpacing()}
        popupWidth={
          params?.IsInMinicart !== '1'
            ? 'max-w-[1060px] fullfillment-popup'
            : 'max-w-[380px] minCartFulfillMent'
        }
        componentName={componentName}
        showSmallPopup={params?.IsInMinicart !== '1' ? false : true}
      >
        <>
          <div className={deliveryTopTextSection()}>
            {fields?.dialogTitle && (
              <Text field={fields?.dialogTitle} className={dialogueTitle()} tag="h4" />
            )}
            {!findStorePopupOpen && (
              <>
                {fields?.dialogShortDescription && (
                  <Text
                    field={fields?.dialogShortDescription}
                    tag="p"
                    className={dialogueShortDescription()}
                  />
                )}
              </>
            )}
            {fields?.fieldsRequiredMessage && (
              <Text
                field={fields?.fieldsRequiredMessage}
                tag="p"
                className={FieldsRequiredMessage()}
              />
            )}
          </div>
          <div className="formSection">
            {!findStorePopupOpen && (
              <Formik
                initialValues={initialValues}
                validationSchema={FindStorePopupSchema}
                enableReinitialize={true}
                onSubmit={(
                  values: FormValuesTypes,
                  { setSubmitting }: FormikHelpers<FormValuesTypes>
                ) => {
                  handleDeliveryPopupSubmitAction(values);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting, errors, touched }) => (
                  <FormikForm name="deliveryAvailability_FindStore_Popup" className={formWrapper()}>
                    {showHardError && (
                      <div className={hardError()}>
                        <NextImage
                          field={{
                            value: {
                              src: hardErrorIcon?.src,
                              alt: 'hard-error-icon',
                              width: '17',
                              height: '17',
                              class: 'w-[17px] h-[17px]',
                            },
                          }}
                        />
                        {fields?.addressNotLocatedMessage?.value}
                      </div>
                    )}
                    {showNoDeliveryError && (
                      <div className={hardError()}>
                        <NextImage
                          field={{
                            value: {
                              src: hardErrorIcon?.src,
                              alt: 'hard-error-icon',
                              width: '17',
                              height: '17',
                              class: 'w-[17px] h-[17px]',
                            },
                          }}
                        />
                        {fields?.notAbleToDeliverMessage?.value}
                      </div>
                    )}

                    <div className={formContainer()}>
                      <div className={formFieldRow()}>
                        <div className={formField()}>
                          <label htmlFor="addressLine1" className={fieldLabel()}>
                            <Text field={fields?.addressLine1FieldTitle} />
                          </label>
                          <AutoCompleteWrapper
                            onHandleChange={(value: { lat: number; lng: number }) => {
                              setCoordinates({
                                lat: value?.lat,
                                lng: value?.lng,
                              });
                            }}
                            fieldMap={autoCompleteFieldMap}
                          >
                            {({}: any) => {
                              return (
                                <>
                                  <Field
                                    id="AddressLine1"
                                    name="AddressLine1"
                                    aria-label="AddressLine1"
                                    placeholder=""
                                    className={clsx(
                                      inputField(),
                                      `${getBorderStyle(errors, touched, 'AddressLine1')}`
                                    )}
                                  />
                                </>
                              );
                            }}
                          </AutoCompleteWrapper>
                          <ErrorMessage
                            name="AddressLine1"
                            className={errorStyle()}
                            component="div"
                          />
                        </div>

                        <div className={formField()}>
                          <label htmlFor="addressLine2" className={fieldLabel()}>
                            <Text field={fields?.addressLine2FieldTitle} />
                          </label>
                          <Field
                            id="addressLine2"
                            aria-label="addressLine2"
                            name="addressLine2"
                            placeholder=""
                            className={clsx(
                              inputField(),
                              `${getBorderStyle(errors, touched, 'addressLine2')}`
                            )}
                          />
                          <ErrorMessage
                            name="addressLine2"
                            className={errorStyle()}
                            component="div"
                          />
                        </div>
                      </div>
                      <div className={formFieldRow()}>
                        <div className={formField()}>
                          <label htmlFor="city" className={fieldLabel()}>
                            <Text field={fields?.cityFieldTitle} />
                          </label>
                          <Field
                            id="city"
                            name="city"
                            aria-label="city"
                            placeholder=""
                            className={clsx(
                              inputField(),
                              `${getBorderStyle(errors, touched, 'city')}`
                            )}
                          />
                          <ErrorMessage name="city" className={errorStyle()} component="div" />
                        </div>

                        <div className={formField()}>
                          <label htmlFor="stateCode" className={fieldLabel()}>
                            <Text field={fields?.stateFieldTitle} />
                          </label>
                          <Field
                            as="select"
                            id="stateCode"
                            name="stateCode"
                            aria-label="stateCode"
                            placeholder=""
                            className={clsx(
                              dropDownField(),
                              `${getBorderStyle(errors, touched, 'stateCode')}`
                            )}
                            // onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            //   const selectedStateCode = event?.target?.value;
                            //   const selectedStateText =
                            //     event?.target?.options[event.target?.selectedIndex].text;
                            //   setFieldValue('stateCode', selectedStateCode);
                            //   setFieldValue('state', selectedStateText);
                            // }}
                          >
                            <option value="">{getDictionaryValue('PleaseSelectOption')}</option>
                            {getStatesOptions('US')}
                          </Field>
                          <ErrorMessage name="stateCode" className={errorStyle()} component="div" />
                        </div>
                      </div>
                      <div className={formFieldRow()}>
                        <div className={formField()}>
                          <label htmlFor="ZipCode" className={fieldLabel()}>
                            <Text field={fields?.zipFieldTitle} />
                          </label>
                          <Field
                            id="ZipCode"
                            name="ZipCode"
                            aria-label="ZipCode"
                            placeholder=""
                            className={clsx(
                              inputField(),
                              `${getBorderStyle(errors, touched, 'ZipCode')}`
                            )}
                          />
                          <ErrorMessage name="ZipCode" className={errorStyle()} component="div" />
                        </div>
                        <div className={formField()}></div>
                      </div>
                    </div>
                    <div className={formFieldRow()}>
                      <div className={formField()}>
                        {/* <Field type="hidden" id="state" name="state" /> */}
                        <Field type="hidden" id="country" name="country" aria-label="country" />
                      </div>
                    </div>
                    <div className={clsx(formFieldRow(), formSubmitRow())}>
                      <div className={clsx(formField(), 'min-w-[260px]')}>
                        {loading ? (
                          <div className={loaderWrapper()}>
                            <Loader />
                            <p>{getDictionaryValue('FindingStores')}</p>
                          </div>
                        ) : (
                          <button
                            aria-label="find store"
                            className={submitBtn()}
                            type="submit"
                            data-disabled={isSubmitting}
                            disabled={isSubmitting}
                          >
                            {fields?.findStoreButtonText?.value}
                          </button>
                        )}
                      </div>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            )}
            <div className={findStoreContainer()}>
              {findStorePopupOpen && (
                <>
                  {showHardError && (
                    <div className={hardError()}>
                      <NextImage
                        field={{
                          value: {
                            src: hardErrorIcon?.src,
                            alt: 'hard-error-icon',
                            width: '17',
                            height: '17',
                            class: 'w-[17px] h-[17px]',
                          },
                        }}
                      />
                      {fields?.addressNotLocatedMessage?.value}
                    </div>
                  )}
                  {showSoftError && (
                    <div className={softError()}>
                      <NextImage
                        field={{
                          value: {
                            src: softErrorIcon?.src,
                            alt: 'soft-error-icon',
                            width: '17',
                            height: '17',
                            class: 'w-[17px] h-[17px]',
                          },
                        }}
                      />
                      {fields?.unableToLocateErrorMessage?.value}
                    </div>
                  )}
                  <div className={changeStoreWrapper()}>
                    {formShippingAddress && (
                      <p>
                        {formShippingAddress.Street1}, {formShippingAddress.City},{' '}
                        {formShippingAddress.State}, {formShippingAddress.Zip},{' '}
                        {formShippingAddress.Country}{' '}
                      </p>
                    )}
                    {fields?.changeAddressCTAText?.value && (
                      <LinkHelper
                        className={switchToLink()}
                        onClick={(e) => {
                          e.preventDefault();
                          handleChangeAddressLink();
                        }}
                        field={{
                          value: {
                            href: '/',
                            text: fields?.changeAddressCTAText?.value,
                          },
                        }}
                      />
                    )}
                  </div>
                </>
              )}
              {renderStoreWidget && (
                <>
                  {componentContextData?.openDeliveryAvailabilityPopupFromPDP ? (
                    <>
                      <GetStoresWidget
                        rfkId="rfk_Store_Locator_Geo_Location"
                        setFindStorePopupOpen={setFindStorePopupOpen}
                        fields={fields}
                        coordinates={coordinates}
                        handleModalClose={handleModalClose}
                        showMainLoader={setMainLoader}
                        formShippingAddress={formShippingAddress}
                        productId={componentContextData?.openDeliveryAvailabilityPopupFromPDP}
                      />
                    </>
                  ) : (
                    <div
                      className={clsx(
                        'storewidget',
                        (params?.IsInMinicart === '1' && 'lg:min-h-[500px]') || ''
                      )}
                    >
                      <FindStorePopupWidget
                        rfkId="rfk_Store_Locator_Geo_Location"
                        fields={fields}
                        handleModalClose={handleModalClose}
                        coordinates={coordinates}
                        showMainLoader={setMainLoader}
                        setFindStorePopupOpen={setFindStorePopupOpen}
                        setShowHardError={setShowHardError}
                        setShowNoDeliveryError={setShowNoDeliveryError}
                        setRenderStoreWidget={setRenderStoreWidget}
                        setLoading={setLoading}
                        inMiniCart={params?.IsInMinicart === '1' ? true : false}
                        formShippingAddress={formShippingAddress}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            {fields?.poweredByLogoImage && (
              <div className={clsx(formFieldRow(), formSubmitRow(), 'mt-[30px]')}>
                <div className={clsx(formField(), 'min-w-[260px]', 'items-end')}>
                  <ImageHelper field={fields?.poweredByLogoImage} />
                </div>
              </div>
            )}
          </div>
        </>
      </ModalWrapper>
    </>
  );
};
