import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import Loader from 'components/Loader/Loader';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useSiteNameForApi } from 'src/hooks/useSiteName';
import { SubscriptionResponse } from 'src/utils/apiConfig';
import { apiRequest, ApiRequestOptions } from 'src/utils/apiWrapper';
import { cancelAutoshipModalTailwindVariant } from 'tailwindVariants/components/cancelAutoshipModalTailwindVariant';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { autoshipAPI } from 'src/utils/nextApiConfig';

interface CancelAutoshipProps {
  fields: {
    title?: Field<string>;
    description?: Field<string>;
    warningHeading?: Field<string>;
    confirmCancellationText?: Field<string>;
    loaderText?: Field<string>;
    ctaText?: Field<string>;
    button?: Field<string>;
    enterYourReasonText?: Field<string>;
    reasonForCancellationText?: Field<string>;
    errorMessageForCancellation?: Field<string>;
    reasonList?: {
      fields?: {
        reasonItem?: Field<string>;
      };
    }[];
  };
}
const CancelAutoship = ({ fields }: CancelAutoshipProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reasonOfCancellation, setReasonOfCancellation] = useState<false | string>(false);
  const [otherReasonOfCancellation, setOtherReasonOfCancellation] = useState<false | string>(false);
  const {
    base,
    title,
    description,
    button,
    warningHeading,
    buttonWrapper,
    loadingWrapper,
    buttonWithBg,
    dropdownIcon,
    contentWrapper,
    reasonWrapper,
    cancellationReasonTitle,
    cancellationReasonSelect,
    cancellationReasonInput,
    cancellationReasonSelectWrapper,
    cancellationReasonSelectWrapperInput,
    errorMessage,
  } = cancelAutoshipModalTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  const handleModalClose = () => {
    setIsLoading(false);
    setIsError(false);
    setReasonOfCancellation(false);
    setOtherReasonOfCancellation(false);
    setAutoshipContextData({
      ...autoshipContextData,
      isCancelClicked: false,
      subscriptionData: false,
    });
  };

  const site = useSiteNameForApi();
  const token = useAuthToken();
  const onCancelClicked = async () => {
    //TODO: need to refactor this in future (this static message)
    if (reasonOfCancellation && reasonOfCancellation != 'Choose from these options') {
      setIsLoading(true);
      const cancellationReason =
        reasonOfCancellation === 'Other'
          ? otherReasonOfCancellation
            ? otherReasonOfCancellation
            : reasonOfCancellation
          : reasonOfCancellation;

      const options: ApiRequestOptions = {
        method: 'POST',
        data: {
          SubscriptionId: autoshipContextData.subscriptionData.ID,
          CancellationReason: cancellationReason,
        },
        headers: {
          Authorization: token,
          site: site,
        },
      };
      const response = await apiRequest<SubscriptionResponse>(autoshipAPI?.cancelAutoship, options);
      setIsLoading(false);
      setReasonOfCancellation(false);
      setIsError(false);
      setOtherReasonOfCancellation(false);
      setAutoshipContextData({
        ...autoshipContextData,
        isCancelClicked: false,
        subscriptionData: false,
        isCancelledSuccessfully: response.Success,
        cancelledAutoshipId: autoshipContextData?.subscriptionData?.ID,
        autoshipEndDate: DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ"),
        autoshipCancellationReason: cancellationReason,
      });
    } else {
      setIsError(true);
    }
  };
  if (fields === undefined || fields === null) return <></>;

  return (
    <>
      <ModalWrapper
        showCloseButtonInModalHeader={false}
        closeModalOnOverlayClick={true}
        onCloseClick={handleModalClose}
        showModal={autoshipContextData?.isCancelClicked && autoshipContextData?.subscriptionData}
        customPopup={true}
        popupWidth="max-w-[997px]"
        popupSpacing="p-0"
      >
        <div className={base()}>
          <RichText className={title()} field={fields?.title} />
          <div className={contentWrapper()}>
            <RichText className={description()} field={fields?.description} />
            <RichText className={warningHeading()} field={fields?.warningHeading} />
            <div className={reasonWrapper()}>
              <TextHelper
                field={fields?.reasonForCancellationText}
                className={cancellationReasonTitle()}
              />
              <div className={cancellationReasonSelectWrapper()}>
                <select
                  aria-label="cancellationReason"
                  className={cancellationReasonSelect({
                    isError: isError,
                  })}
                  onChange={(e) => {
                    setIsError(false);
                    setReasonOfCancellation(e.target?.value);
                  }}
                  value={reasonOfCancellation ? reasonOfCancellation : ''}
                >
                  {fields?.reasonList?.map((reason, index) => (
                    <option key={index} value={reason?.fields?.reasonItem?.value}>
                      {reason?.fields?.reasonItem?.value}
                    </option>
                  ))}
                </select>
                <IconHelper className={dropdownIcon()} icon="dropdown" />
              </div>
              {isError ? (
                <RichText className={errorMessage()} field={fields?.errorMessageForCancellation} />
              ) : null}
            </div>
            {reasonOfCancellation === 'Other' && (
              <div className={reasonWrapper()}>
                <TextHelper
                  field={fields?.enterYourReasonText}
                  className={cancellationReasonTitle()}
                />
                <div className={cancellationReasonSelectWrapperInput()}>
                  <input
                    aria-label="cancel autoship input"
                    className={cancellationReasonInput()}
                    onChange={(e) => {
                      setOtherReasonOfCancellation(e.target?.value);
                    }}
                  ></input>
                </div>
              </div>
            )}
            <div className={buttonWrapper()}>
              {isLoading ? (
                <div className={loadingWrapper()}>
                  <Loader />
                  {fields?.loaderText?.value}
                </div>
              ) : (
                <>
                  {fields?.confirmCancellationText?.value && (
                    <button
                      aria-label="confirm cancellation"
                      className={buttonWithBg()}
                      onClick={onCancelClicked}
                    >
                      {fields?.confirmCancellationText?.value}
                    </button>
                  )}
                </>
              )}
              {fields?.ctaText?.value && (
                <button
                  aria-label="close button"
                  className={button({
                    isLoading,
                  })}
                  onClick={handleModalClose}
                >
                  {fields?.ctaText?.value}
                </button>
              )}
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default CancelAutoship;
