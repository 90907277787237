import { tv } from 'tailwind-variants';

//component variants, added desktop css class in slots as direct in desktop it is not working properly
export const productDetailTailwindVariant = tv(
  {
    slots: {
      base: 'pDPComponent container',
      title:
        'flex text-heading-desk-large-bold leading-heading-desk-large-bold font-heading-desk-large-bold font-primary',
      learnMoreLink:
        'min-w-fit h-fit text-body-large-bold font-body-large-bold font-primary leading-body-large-bold underline text-color-scale-8-dark',
      productDetailsWrapper: 'flex flex-col w-full',
      productOptionText: 'ml-[6.5px]',
      deliveryOptionsWrapper: 'flex',
      deliveryOptionWrapper: ' h-fit flex flex-col border-2 border-color-mid lg:max-w-[269px]',
      productOptionsWrapper: 'pl-[7px] font-primary',
      productOptionsWrapperForPickup:
        'pl-[7px] font-primary !mb-mob-margin-base-bottom lg:!mb-desk-margin-tight-bottom',
      productOptions: 'flex gap-3 lg:flex-nowrap lg:max-w-[577px] flex-wrap',
      productOption: 'flex flex-col w-full lg:max-w-[279px]',
      productOptionLabel:
        'font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      productOptionDropdown:
        'lg:max-w-[279px] cursor-pointer appearance-none border-2 focus:outline-none w-full h-[48px] border-color-scale-mid',
      dropdownIcon: 'absolute cursor-pointer top-1/2 transform -translate-y-1/2 right-2',
      pickUpOptionLabel:
        'font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      pickUpOptionText:
        'font-primary text-body-large-regular font-body-large-regular leading-body-large-regular',
      changeStoreText:
        'flex w-fit group justify-start items-center font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-text-brand-1 focus:text-color-brand-tertiary-3-base hover:text-color-brand-tertiary-3-base',
      changeStoreIcon:
        'w-4 h-4 [&_svg]:max-w-4 [&_svg]:max-h-4 group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:fill-color-brand-tertiary-3-base [&>svg>*]:fill-color-text-brand-1',
      autoShipOptionWrapper:
        'h-fit flex flex-col w-full border-2 border-color-mid lg:max-w-[270px]',
      autoShipWrapper: 'flex flex-col',
      toolbarIcon: 'h-fit cursor-pointer [&>svg>g>*]:fill-color-text-brand-1',
      autoShipText:
        'relative flex font-primary text-body-large-bold font-body-large-bold leading-body-large-bold lg:ml-desk-space-between-tight-horizontal',
      autoShipLabel:
        'w-full bg-pdp-option-background text-color-text-white text-body-small-regular font-body-small-regular leading-body-small-regular',
      centerDiv: 'flex relative cursor-pointer',
      relativeDiv: 'flex relative',
      tooltipContainer: 'relative w-full h-full p-2 pb-3 lg:p-4',
      tooltipWrapper:
        'absolute top-1/2 -translate-y-1/2 right-[39px] w-[193px] lg:w-[320px] bg-color-background-light text-color-text-black border-2 border-color-border-mid text-sm shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] z-10',
      topTooltipWrapper:
        'absolute left-1/2 -translate-x-1/2 bottom-[calc(100%+10px)] w-[193px] lg:w-[320px] bg-color-background-light text-color-text-black border-2 border-color-border-mid text-sm shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] z-10',
      tooltipArrowFirstDiv:
        'absolute top-1/2 left-full border-[12px] border-solid border-t-transparent border-r-transparent border-b-transparent border-color-border-mid -translate-y-1/2',
      tooltipArrowSecondDiv:
        'border-[12px] absolute top-1/2 -translate-y-1/2 -right-[21px] border-solid border-t-transparent border-r-transparent border-b-transparent border-l-color-background-light',
      topTooltipArrowFirstDiv:
        'absolute left-1/2 top-full border-[12px] border-solid border-l-transparent border-r-transparent border-b-transparent border-color-border-mid -translate-x-1/2',
      topTooltipArrowSecondDiv:
        'border-[12px] absolute left-1/2 -translate-x-1/2 -bottom-[21px] border-solid border-l-transparent border-r-transparent border-b-transparent border-t-color-background-light',
      toolTipTitle:
        'font-primary mt-4 font-body-large-bold text-body-large-bold leading-body-large-bold underline',
      topToolTipDescription:
        'font-primary text-body-small-bold font-body-small-bold leading-body-small-bold gap-4 flex flex-col',
      tooltipPhoneNumber: 'text-color-text-brand-2',
      toolTipDescription:
        'pt-[2px] pl-[20px] list-disc font-primary text-body-small-bold font-body-small-bold leading-body-small-bold',
      productActionWrapper: 'flex items-center flex-wrap lg:gap-[55px]',
      addToFavoritesWrapper: 'flex max-w-[148px] font-primary text-left',
      addToCartButton:
        'h-fit bg-color-brand-primary-1-base w-fit min-w-fit text-color-text-white font-primary',
      productQtyWrapper: 'relative w-[130px]',
      productQtyInput:
        'w-[130px] px-[15px] focus:outline-none font-primary text-color-text-black placeholder:text-color-text-black h-[62px] border-[1px] border-color-border-mid',
      productInput:
        'max-w-[40px] outline-none focus:outline-none text-color-text-black placeholder:text-color-text-black font-primary text-heading-desk-large-regular leading-heading-desk-large-regular font-heading-desk-large-regular',
      productQtyButtonWrapper:
        'absolute bottom-[1px] w-[31px] h-[60px] bg-color-background-light right-[1px] flex flex-col',
      productQtyButton: 'w-full h-1/2 text-[17px] font-[700]',
      availabilityWrapper:
        'flex font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      priceText: 'font-primary lg:mr-desk-space-between-tight-vertical',
      priceWrapper: 'flex flex-col',
      storePickUpText: ' font-primary ',
      loginText: 'font-primary',
      pricesWrapper: 'mt-[23px]',
      priceContainer: 'flex flex-col ',
      productInfoWrapper: 'w-full flex',
      heartIconWithTitle:
        'cursor-pointer inline-flex w-4 h-4 [&_svg]:w-4 [&_svg]:h-4 lg:ml-2 [&>svg>*>*]:fill-color-brand-primary-1-base',
      ratingIcon: 'inline-flex w-4 h-4 [&_svg]:w-4 [&_svg]:h-4',
      stockAvailability: '',
      checkAvailabilityText:
        'flex group w-fit justify-start items-center font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-text-brand-1 focus:text-color-brand-tertiary-3-base hover:text-color-brand-tertiary-3-base',
      errorMessage:
        'w-full text-body-large-bold font-primary font-body-large-bold leading-body-large-bold text-color-accent-red',
      radioButton:
        'h-6 w-6 cursor-pointer min-h-6 min-w-6 appearance-none border border-color-border-dark rounded-full outline-none shadow-[inset_22px_0px_6px_6px_color-brand-primary-1-base]',
      relative: 'relative',
      toolbarIconWrapper: 'relative h-fit',
      storeName:
        'underline cursor-pointer ml-mob-space-between-micro-horizontal lg:ml-desk-space-between-micro-horizontal',
      titleWrapper: 'inline-flex w-full',
      addToFavoriteIcon: 'inline-flex [&>svg>*>*]:fill-color-brand-primary-1-base',
      radioButtonFiller:
        'cursor-pointer absolute top-[1px] left-[1px] h-[22px] w-[22px] bg-color-brand-primary-1-base rounded-full',
      reviewWrapper:
        'flex mt-2 lg:mt-0 font-primary gap-mob-space-between-tight-horizontal lg:gap-desk-space-between-tight-horizontal text-body-large-bold font-body-large-bold leading-body-large-bold',
      reviewRatingWrapper: 'flex items-center',
      reviewRating: 'flex items-center',
      reviewNumber: 'text-color-text-dark',
      reviewText:
        'text-color-brand-primary-1-base underline focus:text-color-brand-tertiary-3-base hover:text-color-brand-tertiary-3-base',
      inputWrapper:
        'text-color-text-black placeholder:text-color-text-black font-primary text-heading-desk-medium-regular leading-heading-desk-medium-regular font-heading-desk-medium-regular absolute top-1/2 left-[15px] -translate-y-1/2 flex gap-[17px] items-center',
      tooltipText: '',
    },
    variants: {
      size: {
        desktop: {
          title: 'block',
          tooltipText: 'gap-desk-margin-micro-bottom',
          reviewRatingWrapper: 'gap-desk-space-between-micro-horizontal',
          reviewWrapper: 'mt-0 gap-desk-space-between-tight-horizontal',
          pricesWrapper: 'flex justify-between flex-wrap',
          heartIconWithTitle: 'mt-0 ',
          base: 'flex py-desk-padding-tight-y gap-desk-space-between-base-horizontal',
          productDetailsWrapper: 'gap-desk-margin-base-bottom',
          deliveryOptionsWrapper: 'flex-row gap-desk-space-between-loose-horizontal',
          deliveryOptionWrapper:
            'w-1/2 gap-desk-margin-tight-bottom rounded-desk-global-radius-small px-desk-padding-micro-x py-desk-padding-micro-y',
          availabilityWrapper: 'mt-0',
          productOptionsWrapperForPickup:
            'mt-0 mb-desk-margin-tight-bottom font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',

          productOptionsWrapper:
            'mt-0 mb-desk-margin-tight-bottom font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productOption: 'gap-desk-margin-micro-bottom w-1/2',
          productOptionDropdown:
            'rounded-desk-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
          pickUpOptionLabel: 'ml-desk-space-between-tight-horizontal',
          changeStoreText: 'gap-desk-space-between-micro-horizontal',
          autoShipOptionWrapper: 'max-w-[270px] w-1/2 rounded-desk-global-radius-small',
          autoShipWrapper:
            'gap-desk-margin-tight-bottom px-desk-padding-micro-x py-desk-global-spacing-spacing-3',
          autoShipText:
            'gap-desk-space-between-tight-horizontal ml-desk-space-between-tight-horizontal',
          autoShipLabel: 'px-desk-global-spacing-spacing-3 py-desk-padding-micro-y',
          tooltipWrapper: 'rounded-desk-global-radius-medium',
          topTooltipWrapper: 'rounded-desk-global-radius-medium',
          productActionWrapper: 'flex-row pt-desk-margin-base-top',
          addToFavoritesWrapper:
            'gap-desk-space-between-tight-horizontal text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          addToCartButton:
            'w-fit px-desk-component-button-full-padding-x rounded-desk-global-radius-small py-desk-component-button-full-padding-y text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productQtyInput:
            'text-heading-desk-medium-regular leading-heading-desk-medium-regular font-heading-desk-medium-regular rounded-desk-global-radius-small',
          productQtyButtonWrapper:
            'rounded-br-desk-global-radius-small rounded-tr-desk-global-radius-small',
          priceText:
            'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          priceWrapper: 'gap-desk-space-between-micro-vertical',
          storePickUpText:
            'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          loginText:
            'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          priceContainer: 'gap-desk-space-between-micro-vertical',
          productInfoWrapper: 'flex-row gap-desk-space-between-loose-horizontal',
          stockAvailability: 'ml-desk-space-between-micro-horizontal',
          checkAvailabilityText: 'gap-desk-space-between-micro-horizontal',
        },
        mobile: {
          tooltipText: 'gap-mob-margin-micro-bottom',
          title: 'flex-col',
          heartIconWithTitle: 'mt-[3px]',
          reviewRatingWrapper: 'gap-mob-space-between-micro-horizontal',
          reviewWrapper: 'mt-2 gap-mob-space-between-tight-horizontal',
          availabilityWrapper: 'mt-[23px]',
          productDetailsWrapper: 'gap-mob-space-between-loose-vertical',
          base: 'py-mob-padding-tight-y gap-mob-space-between-base-horizontal',
          deliveryOptionsWrapper: 'flex-col gap-mob-space-between-loose-vertical',
          deliveryOptionWrapper:
            'w-full gap-mob-margin-tight-bottom rounded-mob-global-radius-small px-mob-padding-micro-x py-mob-padding-micro-y',
          productOptionsWrapperForPickup:
            'mb-mob-margin-tight-bottom mt-mob-margin-loose-top font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productOptionsWrapper:
            'mb-mob-margin-tight-bottom mt-mob-margin-loose-top font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productOption: 'gap-mob-margin-micro-bottom',
          productOptionDropdown:
            'rounded-mob-global-radius-small py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-4',
          pickUpOptionLabel: 'ml-mob-space-between-tight-horizontal',
          changeStoreText: 'gap-mob-space-between-micro-horizontal',
          autoShipOptionWrapper: 'rounded-mob-global-radius-small',
          autoShipWrapper: 'gap-3 px-mob-padding-micro-x py-mob-global-spacing-spacing-3',
          autoShipText:
            'gap-mob-space-between-tight-horizontal ml-mob-space-between-tight-horizontal',
          autoShipLabel: 'px-mob-global-spacing-spacing-3 py-mob-padding-micro-y',
          tooltipWrapper: 'rounded-mob-global-radius-medium',
          topTooltipWrapper: 'rounded-mob-global-radius-medium',
          productActionWrapper: 'flex-col pt-[22px] gap-mob-space-between-loose-vertical',
          addToFavoritesWrapper:
            'gap-mob-space-between-tight-horizontal text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          addToCartButton:
            'w-full px-mob-component-button-full-padding-x rounded-mob-global-radius-small py-mob-component-button-full-padding-y text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productQtyInput:
            'text-heading-mob-medium-regular leading-heading-mob-medium-regular font-heading-mob-medium-regular rounded-mob-global-radius-small',
          productQtyButtonWrapper:
            'rounded-br-mob-global-radius-small rounded-tr-mob-global-radius-small',
          priceText:
            'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold mr-mob-space-between-tight-vertical',
          priceWrapper: 'gap-mob-space-between-micro-vertical',
          storePickUpText:
            'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          loginText:
            'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          priceContainer: 'gap-mob-space-between-micro-vertical',
          productInfoWrapper: 'flex-col gap-mob-space-between-base-vertical',
          stockAvailability: 'ml-mob-space-between-micro-horizontal',
          checkAvailabilityText: 'gap-mob-space-between-micro-horizontal',
        },
      },
      priceText: {
        hasImapPrice: {
          priceText: 'underline cursor-pointer',
        },
        hasMemberPrice: {
          priceText: 'text-pdp-red',
        },
        hasListPrice: {
          priceText: '',
        },
        isListPriceWithMemberPrice: {
          priceText: 'text-color-scale-5-mid line-through',
        },
      },
      isError: {
        true: {
          addToCartButton: 'bg-color-brand-primary-1-disabled cursor-not-allowed',
        },
        false: {
          addToCartButton:
            'hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
      availability: {
        InStock: { stockAvailability: 'text-color-global-in-stock' },
        OutOfStock: { stockAvailability: 'text-color-global-outof-stock' },
        LowStock: { stockAvailability: 'text-color-global-low-stock' },
      },
      isPickUpSelected: {
        true: {
          deliveryOptionWrapper: 'border-color-border-brand',
          autoShipOptionWrapper: 'border-color-border-brand ',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
